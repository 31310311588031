.register-form {
    .register-form-row {
        .register-form-block {
            .register-form-checkbox {
                cursor: pointer;
            
                display: block;
                position: relative;
            
                width: auto;

                @media screen and (max-width: 767px) {
                  width: 80%;
                  margin: 15px auto 0;
                }
            
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            
                font-size: 10px;
                line-height: 16px;
                
                font-weight: 600;
                color: #fff;
            
                padding-left: 26px;
            
                margin-top: 15px;
                margin-bottom: 0;
            
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
            
                input:checked ~ .checkmark {
                    background-color: #2196f3;
                    border: 0;
                }
            
                input:checked ~ .checkmark:after {
                    display: block;
                }
            
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
            
                    height: 16px;
                    width: 16px;
            
                    background-color: #eee;
            
                    border-radius: 5px;
            
                    transition: 0.2s ease;
            
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
            
                        left: 5px;
                        top: 2px;
            
                        width: 4px;
                        height: 8px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
            
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            
                &:hover {
                    .checkmark {
                        background-color: #ccc;
                    }
                }
            
                a {
                    color: #6AA9FF;
                    text-decoration: underline;
                }
            }
        }
    }
}
