@import "./progress-bar.scss";
@import "./register-form.scss";

.double-container.register {
    .double-container-row {
        .left-container {
            display: flex;
            flex-wrap: wrap;
            position: fixed;
            width: 35%;
        }

        .right-container {
            background: url("../../../images/sun-gray.jpg") no-repeat;
            background-size: cover;
            background-position: center;
            margin-left: 35%;
            width: calc(65% + 20px);
            padding: 50px 45px 50px 65px;
        }
    }
}

@media screen and (max-width: 767px) {
    .double-container.register {
        .double-container-row {
            .left-container {
                width: 100%;
                position: relative;
            }
            .right-container {
                width: 100%;
                padding: 50px 0 30px 0;
                margin: 0;
                margin-top: -20px;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .double-container.register {
        .double-container-row {
            .left-container {
                width: 100%;
                position: relative;
            }
            .right-container {
                width: 100%;
                padding: 50px 20px 30px 20px;
                margin: 0;
                margin-top: -20px;
            }
        }
    }
}

// @media screen and (min-width: 1024px) and (max-width: 1439px) {
//     .double-container.register {
//         .double-container-row {
//             .left-container {    
//                 width: 45%;
//                 position: relative;
//             }
    
//             .right-container {    
//                 width: calc(55% + 20px);
//             }
//         }
//     }
// }