.register-form-card {
    margin: 15px 0;
    position: relative;

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    height: 280px;

    z-index: 1;

    &::before {
        position: absolute;
        content: "";

        right: 0;

        width: 85%;
        height: 100%;

        background: rgba(0, 0, 0, 0.25);

        border: 1px solid #444444;
        border-radius: 15px;
        z-index: -1;
    }

    .card-block {
        position: relative;

        width: 300px;
        height: 225px;

        .card-front {
            position: absolute;

            top: 0;
            left: 0;

            width: 250px;
            height: 150px;

            background: rgb(253, 253, 253);
            background: linear-gradient(45deg, rgba(253, 253, 253, 1) 0%, rgba(255, 255, 255, 1) 100%);

            border-radius: 10px;
            z-index: 1;

            -webkit-box-shadow: 17px 21px 20px 0px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 17px 21px 20px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 17px 21px 20px 0px rgba(0, 0, 0, 0.3);

            .card-content {
                position: relative;

                display: flex;
                align-items: center;

                width: 100%;
                height: 100%;

                .card-icon {
                    position: absolute;

                    top: 10px;
                    right: 10px;

                    height: 30px;

                    img {
                        width: auto;
                        height: 30px;

                        object-fit: contain;
                    }
                }

                .card-number {
                    display: flex;
                    width: 100%;

                    padding: 0 20px;
                    justify-content: space-between;

                    font-family: Menlo, sans-serif;

                    font-size: 18px;
                    font-weight: 400;

                    text-transform: uppercase;
                    color: #000;
                }

                .card-owner {
                    position: absolute;

                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    left: 0;
                    bottom: 10px;

                    width: 100%;
                    padding: 0 20px;

                    font-family: Menlo, sans-serif;

                    font-size: 12px;
                    font-weight: 400;

                    text-transform: uppercase;
                    color: #000;

                    letter-spacing: 2px;

                    .name {
                        display: flex;
                        flex-wrap: wrap;
                        width: 150px;

                        div {
                            margin-right: 5px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                    .expire {
                        display: flex;

                        .divider {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }

        .card-back {
            position: absolute;

            top: 60px;
            left: 50px;

            width: 250px;
            height: 150px;

            background: rgb(220, 220, 220);
            background: linear-gradient(0deg, rgba(220, 220, 220, 1) 0%, rgba(255, 255, 255, 1) 100%);

            border-radius: 10px;
            z-index: 0;

            -webkit-box-shadow: 17px 21px 20px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 17px 21px 20px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 17px 21px 20px 0px rgba(0, 0, 0, 0.2);

            .card-content {
                position: relative;

                display: flex;
                align-items: flex-end;

                width: 100%;
                height: 100%;

                .line {
                    position: absolute;

                    top: 28px;
                    left: 0;

                    width: 100%;
                    height: 32px;

                    background: #15191d;
                }
                .cvv {
                    position: absolute;

                    bottom: 15px;
                    right: 15px;

                    width: 60px;

                    border-radius: 10px;
                    background: #fff;

                    font-family: Menlo, sans-serif;
                    line-height: 30px;

                    text-align: center;

                    color: #000;
                }
            }
        }
    }

    .card-form {
        width: calc(100% - 300px);
        padding: 0 60px;

        .card-form-row {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;

            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .card-form-block {
                margin: 0 10px;

                .card-input {
                    width: 100%;
                    height: 50px;

                    padding: 0 10px;
                    margin-right: 10px;

                    background: #ffffff;
                    border: none;
                    outline: none;

                    border-radius: 15px;

                    -webkit-appearance: none;

                    /* font */
                    font-family: Menlo, sans-serif;

                    font-size: 14px;
                    line-height: 18px;

                    color: #000;
                    font-weight: 400;

                    letter-spacing: 5px;
                    text-align: center;

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.35);
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .card-number {
                    display: flex;
                    flex-wrap: nowrap;

                    margin-left: -10px;
                    margin-right: -10px;

                    .card-input {
                        width: calc(25% - 20px);
                        margin: 0 10px;
                    }
                }
                .card-expire {
                    display: flex;
                    flex-wrap: nowrap;

                    .card-input {
                        margin: 0;
                        width: 60px;
                    }

                    .divider {
                        align-self: center;
                        margin: 0 10px;

                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }
            .card-form-block.cvv {
                width: 84px;
                .card-input {
                    width: 84px !important;
                    margin: 0;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .register-form-card {
        height: auto;

        &:before {
            top: 0;
            left: -20px;
            right: auto;
            bottom: auto;

            width: calc(100% + 38px);
            height: 75%;
        }

        .card-block {
            order: 2;
            margin: 0 auto;

            .card-back {
                left: auto;
                right: 0;
            }
        }

        .card-form {
            order: 1;

            width: 100%;
            padding: 30px 0;
            .card-form-row {
                .card-form-block {
                    margin: 0 10px;

                    .card-input {
                        font-size: 12px;
                        letter-spacing: 2px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .register-form-card {
        height: auto;

        &:before {
            top: 0;
            left: -20px;
            right: auto;
            bottom: auto;

            width: calc(100% + 38px);
            height: 75%;
        }

        .card-block {
            order: 2;
            margin: 0 auto;

            .card-back {
                left: auto;
                right: 0;
            }
        }

        .card-form {
            order: 1;

            width: 100%;
            padding: 30px 15px;
            .card-form-row {
                .card-form-block {
                    margin: 0 10px;

                    .card-input {
                        font-size: 12px;
                        letter-spacing: 2px;
                    }
                }
            }
        }
    }
}