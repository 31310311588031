* {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
}

body {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    line-height: 20px;

    font-weight: 400;
    color: #fff;

    height: 100vh;
    // overflow: hidden;

    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;

    background: #232222;
}

#root {
    height: 100%;
}

/* ------- h1, h2, h3, h4, h5, p, b */
h1,
h2,
h3,
h4,
h5,
p,
a {
    margin: 0px;
    color: #fff;
}

h1 {
    font-size: 64px;
    line-height: 72px;

    font-weight: 900;
}

h2 {
    font-size: 48px;
    line-height: 56px;

    font-weight: 700;
}

h3 {
    font-size: 30px;
    line-height: 36px;

    font-weight: 600;
}

h4 {
    font-size: 24px;
    line-height: 30px;

    font-weight: 600;
}

h5 {
    font-size: 16px;
    line-height: 24px;

    font-weight: 600;
}

p {
    font-size: 18px;
    line-height: 24px;

    color: #fff;
}

b {
    font-weight: 700;
    color: inherit;
}

a {
    text-decoration: none;

    &:hover {
        color: #76D7FF;
    }
}

/* ------------------------ */

/* ------- button */
.button {
    cursor: pointer;

    width: 200px;
    height: 70px;

    background: url("../images/button.png") no-repeat;
    background-size: contain;
    background-position: center;

    border: none;
    outline: none;

    padding-top: 5px;
    font-family: "Gotham Pro", sans-serif;
    font-size: 14px;
    font-weight: 800;

    color: white;
    text-transform: capitalize;

    -webkit-transition: ease 0.3s;
    -moz-transition: ease 0.3s;
    transition: ease 0.3s;

    &:hover {
        filter: brightness(110%);
    }

    &:focus {
        outline: none;
    }
}
/* ------------------------ */

/* removing styles from autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff; 
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%) !important;  
}
/* ------------------------ */


@media screen and (max-width: 767px) {
    body {
        height: auto;
        overflow: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    body {
        height: auto;
        overflow: auto;
    }
}