.progressbar-block {
    width: 50%;
    padding: 30px 20px 30px 40px;

    .progressbar-block-row {
        display: flex;
        flex-wrap: wrap;

        justify-content: center;

        .progressbar-block-title {
            font-size: 14px;
            line-height: 18px;

            font-weight: 600;
            color: #fff;

            text-transform: uppercase;
            margin-bottom: 30px;
        }

        .progressbar {
            display: flex;
            flex-wrap: wrap;
            width: 150px;
            justify-content: center;

            .progressbar-step {
                opacity: 0.1;
                pointer-events: none;

                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                height: 100px;
                width: 100px;
                padding: 15px;

                border: 2px solid #fff;
                border-radius: 99px;

                font-size: 13px;
                line-height: 18px;

                font-weight: 400;
                color: #fff;
                text-align: center;

                margin-bottom: 10px;

                transition: 0.3s ease;

                &::before {
                    position: absolute;
                    content: "";

                    width: 1px;
                    height: 12px;

                    background: #fff;

                    top: -12px;
                    z-index: -1;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }
            }
            .progressbar-step.done {
                opacity: 1;
                pointer-events: all;

                background: #fff;
                color: #000;

                font-weight: 700;

                padding-top: 30px;

                &::after {
                    position: absolute;
                    content: "";

                    top: 20px;

                    width: 16px;
                    height: 16px;

                    -webkit-mask: url("../../../images/icons/checkmark.svg");
                    -webkit-mask-size: contain;
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-position: center;

                    background: #8dc63f;
                }
            }
            .progressbar-step.active {
                opacity: 1;
                pointer-events: all;

                font-weight: 700;

                &::before {
                    background: #979797;
                }
            }
            .progressbar-step.remove-line {
                &::before {
                    display: none;
                }
            }
            .progressbar-label {
                opacity: 0.1;
                pointer-events: none;

                width: 100%;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                padding: 10px 0;

                font-size: 13px;
                line-height: 18px;

                font-weight: 600;
                color: #fff;
                text-align: center;

                margin-bottom: 12px;

                &::before {
                    position: absolute;
                    content: "";

                    width: 1px;
                    height: 12px;

                    background: #fff;

                    top: -12px;
                    z-index: -1;
                }
                &::after {
                    position: absolute;
                    content: "";

                    width: 1px;
                    height: 12px;

                    background: #fff;

                    bottom: -12px;
                    z-index: -1;
                }
            }
            .progressbar-label.active {
                opacity: 1;
                pointer-events: all;

                color: #fff;

                &::before,
                &::after {
                    background: #979797;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .progressbar-block {
        width: 100%;

        padding: 10px 0;

        .progressbar-block-title {
            display: none;
        }
        .progressbar-block-row {

            .progressbar {

                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                overflow-x: auto;
                
                max-width: 470px;

                width: 100%;
                height: 100px;



                &::-webkit-scrollbar {
                    height: 3px;
                }
            
                &::-webkit-scrollbar-track {
                    background: none;
                }
            
                &::-webkit-scrollbar-thumb {
                    background-clip: padding-box;
            
                    border-top: 3px solid rgba(255, 255, 255, 0.05);
            
                    transition: 0.2s ease;
            
                    &:hover {
                        border-top: 3px solid rgba(255, 255, 255, 0.05);
                    }
            
                    &:focus {
                        border-top: 3px solid rgba(255, 255, 255, 0.05);
                    }
                }


                .progressbar-step {
                    height: 70px;
                    width: 70px;

                    padding: 10px;

                    font-size: 11px;
                    line-height: 14px;

                    margin-bottom: 0;
                    margin-left: 30px;

                    &::before {
                        position: absolute;
                        content: "";
    
                        width: 32px;
                        height: 1px;
    
                        background: #fff;
    
                        top: 50%;
                        left: -32px;
                        z-index: -1;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
                .progressbar-step.remove-line {
                    &::before {
                        display: block;
                    }
                }
                .progressbar-step.done {
                    &::after {
                        top: 12px;
                    }
                }
                .progressbar-label {
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .progressbar-block {
        width: 100%;

        padding: 10px 0;

        .progressbar-block-title {
            display: none;
        }
        .progressbar-block-row {
            .progressbar {
                margin-top: 20px;

                display: flex;
                flex-wrap: wrap;
                align-items: center;

                overflow-x: auto;
                
                width: 100%;



                &::-webkit-scrollbar {
                    height: 3px;
                }
            
                &::-webkit-scrollbar-track {
                    background: none;
                }
            
                &::-webkit-scrollbar-thumb {
                    background-clip: padding-box;
            
                    border-top: 3px solid rgba(255, 255, 255, 0.05);
            
                    transition: 0.2s ease;
            
                    &:hover {
                        border-top: 3px solid rgba(255, 255, 255, 0.05);
                    }
            
                    &:focus {
                        border-top: 3px solid rgba(255, 255, 255, 0.05);
                    }
                }


                .progressbar-step {
                    margin-bottom: 0;
                    margin-left: 30px;

                    height: 80px;
                    width: 80px;

                    padding: 10px;

                    font-size: 11px;
                    line-height: 14px;

                    &::before {
                        position: absolute;
                        content: "";
    
                        width: 32px;
                        height: 1px;
    
                        background: #fff;
    
                        top: 50%;
                        left: -32px;
                        z-index: -1;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
                .progressbar-step.done {
                    &::after {
                        top: 15px;
                    }
                }
                .progressbar-step.remove-line {
                    &::before {
                        display: block;
                    }
                }
                .progressbar-label {
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .progressbar-block {
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5px;
        }
    
        &::-webkit-scrollbar-track {
            background: none;
        }
    
        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
    
            border-right: 6px solid rgba(0, 0, 0, 0.2);
    
            transition: 0.2s ease;
    
            &:hover {
                border-right: 6px solid rgba(0, 0, 0, 0.3);
            }
    
            &:focus {
                border-right: 6px solid rgba(0, 0, 0, 0.6);
            }
        }

        .progressbar-block-row {
            .progressbar {
                .progressbar-step:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

}