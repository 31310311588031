.sidebar-nav {
    width: 100%;
    z-index: 1;

    .sidebar-nav-icon-close {
        display: none;
        cursor: pointer;

        width: 20px;
        height: 20px;

        background: #D8D8D8;

        -webkit-mask: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.43579' y='0.454102' width='26.1891' height='2.09513' transform='rotate(45 2.43579 0.454102)' fill='%23D8D8D8'/%3E%3Crect width='26.1891' height='2.09513' transform='matrix(-0.707107 0.707107 0.707107 0.707107 19.4727 0.454102)' fill='%23D8D8D8'/%3E%3C/svg%3E%0A");        -webkit-mask-size: contain;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;

    }

    ul.main {
        width: 100%;
        list-style: none;
        margin-bottom: 0;

        li.main-item {
            position: relative;
            margin-bottom: 20px;

            div.main-link {
                cursor: pointer;
                padding: 0;

                font-size: 14px;
                font-weight: 700;

                color: #fff;
                line-height: 18px;

                text-transform: none;
                transition: 0.1s ease;

                margin-bottom: 10px;

                &:hover {
                    color: #cdcbcb;

                    &::before {
                        background: #cdcbcb;
                    }
                }

                a.active {
                    color: #48b9e8;
                    font-weight: 700;

                    &:hover {
                      color: #76D7FF;
                    }
                }
            }

            ul.sub {
                display: none;

                width: 100%;
                list-style: none;

                margin-bottom: 0;
                margin-top: 10px;

                li.sub-item {
                    padding: 0px 20px;

                    a.sub-link {
                        padding: 0;

                        font-size: 12px;
                        font-weight: 500;

                        color: #fff;
                        line-height: 24px;
                        cursor: default;
                        text-decoration: none;
                    }

                    a.sub-link.active {
                        color: #48b9e8;
                        font-weight: 700;
                    }
                }
            }
            div.main-link.active {
                &::before {
                    transform: rotate(180deg);
                }
            }
        }
        li.main-item.disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }
}
@media screen and (max-width: 767px) {
    .sidebar-nav {
        display: none;

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        padding: 30px 25px;

        background: rgb(22, 21, 21);
        background: linear-gradient(60deg, rgba(22, 21, 21, 1) 0%, rgba(26, 26, 26, 1) 100%);

        border-radius: 10px;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            
            bottom: 0;
            right: 0;

            width: 150px;
            height: 100px;

            background: url('../../../images/rainbow-sidebar.png') no-repeat;
            background-position: right bottom;
            background-size: contain;
        }

        .sidebar-nav-icon-close {
            display: block;
            position: absolute;

            top: 17px;
            right: 30px;

            z-index: 1;
        }

        ul.main {
            display: block;

            li.main-item {
                div.main-link {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &.action {
            display: block;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .sidebar-nav {
        display: none;

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        padding: 30px 25px;

        background: rgb(22, 21, 21);
        background: linear-gradient(60deg, rgba(22, 21, 21, 1) 0%, rgba(26, 26, 26, 1) 100%);

        border-radius: 10px;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            
            bottom: 0;
            right: 0;

            width: 150px;
            height: 100px;

            background: url('../../../images/rainbow-sidebar.png') no-repeat;
            background-position: right bottom;
            background-size: contain;
        }

        .sidebar-nav-icon-close {
            display: block;
            position: absolute;

            top: 30px;
            right: 30px;

            z-index: 1;
        }

        ul.main {
            display: block;

            li.main-item {
                div.main-link {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &.action {
            display: block;
        }
    }
}