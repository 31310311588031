@import "./register-form/register-form-error.scss";
@import "./register-form/register-form-input.scss";
@import "./register-form/register-form-checkbox.scss";
@import "./register-form/register-form-card.scss";
@import "./register-form/register-form-file.scss";
@import "./register-form/download.scss";

.register-form {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        -webkit-text-fill-color: #000 !important;
    }
    position: relative;

    display: flex;
    flex-wrap: wrap;

    align-items: space-between;

    width: 100%;
    height: 100%;
    overflow-y: scroll;

    // -ms-overflow-style: none;
    // scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background-clip: padding-box;

        border-right: 6px solid rgba(0, 0, 0, 0.2);

        transition: 0.2s ease;

        &:hover {
            border-right: 6px solid rgba(0, 0, 0, 0.3);
        }

        &:focus {
            border-right: 6px solid rgba(0, 0, 0, 0.6);
        }
    }

    .register-form-container {
        width: 100%;
    }

    .register-step-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        font-size: 26px;
        line-height: 30px;

        font-weight: 600;
        color: #fff;

        padding: 0 25px;
        margin-bottom: 30px;

        .register-step-not-required {
            margin-top: 9px;
            overflow: visible;
            position: relative;
            padding: 10px 30px;

            font-size: 12px;
            line-height: 20px;

            font-weight: 600;

            font-style: italic;
            color: #fff;

            background: #5ca943;
            border-radius: 20px;

            &:before {
                content: "!";
                position: absolute;

                display: flex;
                justify-content: center;
                align-items: center;

                width: 18px;
                height: 18px;

                background: #fff;
                border: 3px solid #5ca943;

                top: -9px;
                left: 10px;

                border-radius: 99px;

                font-size: 14px;
                font-weight: 900;
                color: #5ca943;

                font-style: normal;
            }
        }
    }

    .register-form-row {
        display: flex;
        flex-wrap: nowrap;

        width: 100%;
        margin-bottom: 20px;

        .register-form-block {
            padding: 0 25px;
            width: 100%;

            label {
                display: block;
                width: 100%;
                margin-bottom: 15px;

                font-size: 16px;
                line-height: 20px;

                font-weight: 700;
                color: #fff;
            }
        }
    }
    .register-form-buttons-row {
        position: relative;

        align-self: flex-end;
        width: 100%;

        display: flex;
        flex-wrap: nowrap;

        .register-form-buttons-block {
            display: flex;

            padding: 0 25px;
            width: 100%;

            .button {
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .register-form-buttons-block.right {
            justify-content: flex-end;

            .button {
                margin-left: 0;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
.button.register-form-button {
    width: 160px;
    height: 56px;

    text-transform: uppercase;
}
.button.register-form-button.gray {
    background: url("../../../images/button-gray.png") no-repeat;
    background-size: contain;
    padding: 0;
}

@media screen and (max-width: 767px) {
    .register-form {
        overflow-y: hidden;
        height: auto;

        .register-step-title {
            .register-step-not-required {
                margin-top: 20px;
            }
        }
        .register-form-row {
            flex-wrap: wrap;
            margin-bottom: 0;

            .register-form-block {
                margin-bottom: 20px;
            }
        }

        .register-form-buttons-row {
            margin-top: 20px;

            flex-wrap: wrap;
            justify-content: center;

            .register-form-buttons-block {
                flex-wrap: wrap;
                justify-content: center !important;
                width: 100%;

                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                .button {
                    margin: 0 15px !important;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .register-form {
        overflow-y: hidden;
        height: auto;

        .register-form-buttons-row {
            margin-top: 20px;

            flex-wrap: wrap;
            justify-content: space-between;

            .register-form-buttons-block {
                flex-wrap: wrap;
                justify-content: center !important;
                width: auto;

                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .register-form {
        .register-form-buttons-row {
            .register-form-buttons-block {
                flex-wrap: wrap;

                .button {
                    margin: 0 !important;
                    margin-bottom: 10px !important;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}
