@import './global.scss';
@import './fonts.scss';

@import './components/double-container.scss';

@import './components/login/login.scss';
@import './components/register/register.scss';
@import './components/sidebar/sidebar.scss';
@import './components/admin/admin.scss';

.c-resize .rbgcp-handle {
  top: -2px !important;
}
