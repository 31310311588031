.download-container {
    width: 100%;
    height: 100%;

    .download-row {
        display: flex;
        flex-wrap: wrap;

        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        .download-block {
            text-align: center;

            .download-title {
                font-size: 26px;
                line-height: 30px;

                font-weight: 600;
                color: #fff;

                margin-bottom: 30px;
            }
            .download-button {
                .button {
                    width: 160px;
                    height: 56px;
                }

                margin-bottom: 30px;
            }
            .download-additional-info {
                font-size: 16px;
                line-height: 24px;

                font-weight: 400;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .download-container {
        height: calc(100vh - 325px);

        .download-row {
            .download-block {
                padding: 0 30px;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .download-container {
        height: calc(100vh - 325px);

        .download-row {
            .download-block {
                padding: 0 30px;
            }
        }
    }
}