@import "./sidebar-nav.scss";
@import "./sidebar-user.scss";

.sidebar {
    position: relative;

    width: 50%;
    height: 100%;

    background: rgb(22, 21, 21);
    background: linear-gradient(30deg, rgba(22, 21, 21, 1) 0%, rgba(26, 26, 26, 1) 100%);

    border-radius: 10px;
    padding: 30px 40px;

    -webkit-box-shadow: 3px 0px 40px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 3px 0px 40px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 3px 0px 40px 0px rgba(0, 0, 0, 0.1);

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background-clip: padding-box;

        border-right: 6px solid rgba(0, 0, 0, 0.2);

        transition: 0.2s ease;

        &:hover {
            border-right: 6px solid rgba(0, 0, 0, 0.3);
        }

        &:focus {
            border-right: 6px solid rgba(0, 0, 0, 0.6);
        }
    }

    &::before {
        position: absolute;
        content: "";

        top: 60%;
        left: 0;

        width: 100%;
        height: 250px;

        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        z-index: 0;
    }
    .sidebar-row {
        display: flex;
        flex-wrap: wrap;

        width: 100%;

        .sidebar-logo {
            width: 100%;
            margin-bottom: 60px;
            text-align: center;
            z-index: 1;
        }

        .sidebar-nav-icon {
            display: none;
            cursor: pointer;

            width: 24px;
            height: 24px;

            background: #d8d8d8;

            -webkit-mask: url("data:image/svg+xml,%3Csvg width='25' height='14' viewBox='0 0 25 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='25' height='2' fill='%23D8D8D8'/%3E%3Crect y='6' width='25' height='2' fill='%23D8D8D8'/%3E%3Crect y='12' width='25' height='2' fill='%23D8D8D8'/%3E%3C/svg%3E%0A");
            -webkit-mask-size: contain;
            -webkit-mask-position: center;
            -webkit-mask-repeat: no-repeat;
        }
    }
    .adminLogo {
        position: absolute;
        top: 72%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 38px;
        font-weight: bold;
        text-shadow: 0 1px 0px #000, 1px 0 0px #000, 1px 2px 1px #000, 2px 1px 1px #000, 2px 3px 2px #000,
            3px 2px 2px #000, 3px 4px 2px #000, 4px 3px 3px #000, 4px 5px 3px #000, 5px 4px 2px #000, 5px 6px 2px #000,
            6px 5px 2px #000, 6px 7px 1px #000, 7px 6px 1px #000, 7px 8px 0px #000, 8px 7px 0px #000, 2px 2px 2px #000;
        transform: rotate(356deg);
    }
}

@media screen and (max-width: 767px) {
    .sidebar {
        position: relative;

        padding: 30px 25px;
        width: 100%;
        background: linear-gradient(60deg, rgba(22, 21, 21, 1) 0%, rgba(26, 26, 26, 1) 100%);

        &::before {
            position: absolute;
            content: "";

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-size: contain;
            background-position: 60% center;
            background-repeat: no-repeat;
            background-image: url("../../../images/rainbow-mobile.png");

            z-index: 0;
        }

        .sidebar-row {
            flex-wrap: wrap;
            justify-content: space-between;

            .sidebar-logo {
                display: none;
                width: 50%;
                margin-bottom: 0;

                img {
                    width: auto;
                    height: 24px;
                    object-fit: contain;
                    object-position: center;
                }
            }
            .sidebar-nav-icon {
                display: block;
                position: absolute;
                top: 17px;
                right: 30px;
                z-index: 1;
            }
        }
        .adminLogo {
            position: absolute;
            top: 57%;
            margin-left: auto;
            margin-right: auto;
            left: 45%;
            right: 0;
            width: 105px;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            text-shadow: 0 1px 0px #000, 1px 0 0px #000, 1px 2px 1px #000, 2px 1px 1px #000, 2px 3px 2px #000,
                3px 2px 2px #000, 3px 4px 2px #000, 4px 3px 3px #000, 4px 5px 3px #000, 5px 4px 2px #000,
                5px 6px 2px #000, 6px 5px 2px #000, 6px 7px 1px #000, 7px 6px 1px #000, 7px 8px 0px #000,
                8px 7px 0px #000, 2px 2px 2px #000;
            transform: rotate(356deg);
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .sidebar {
        position: relative;

        padding: 30px 25px;
        width: 100%;
        background: linear-gradient(60deg, rgba(22, 21, 21, 1) 0%, rgba(26, 26, 26, 1) 100%);

        &::before {
            position: absolute;
            content: "";

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-size: contain;
            background-position: 60% center;
            background-repeat: no-repeat;
            background-image: url("../../../images/rainbow-mobile.png");

            z-index: 0;
        }

        .sidebar-row {
            flex-wrap: wrap;
            justify-content: space-between;

            .sidebar-logo {
                width: 50%;
                margin-bottom: 0;

                img {
                    width: auto;
                    height: 30px;
                    object-fit: contain;
                    object-position: center;
                }
            }
            .sidebar-nav-icon {
                display: block;
            }
        }
        .adminLogo {
            position: absolute;
            top: 40%;
            margin-left: auto;
            margin-right: auto;
            left: 18%;
            right: 0;
            text-align: center;
            font-size: 23px;
            font-weight: bold;
            text-shadow: 0 1px 0px #000, 1px 0 0px #000, 1px 2px 1px #000, 2px 1px 1px #000, 2px 3px 2px #000,
                3px 2px 2px #000, 3px 4px 2px #000, 4px 3px 3px #000, 4px 5px 3px #000, 5px 4px 2px #000,
                5px 6px 2px #000, 6px 5px 2px #000, 6px 7px 1px #000, 7px 6px 1px #000, 7px 8px 0px #000,
                8px 7px 0px #000, 2px 2px 2px #000;
            transform: rotate(356deg);
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .sidebar {
        padding: 30px 30px;
    }
}
