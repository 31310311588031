.register-form {
    .register-form-row {
        .register-form-block {
            .file-input {
                display: flex;
                flex-wrap: nowrap;
            
                justify-content: space-between;
                align-items: center;
            
                width: 100%;
                height: 100px;
            
                background: rgba(0,0,0,0.3);
            
                border-radius: 15px;
                padding: 15px 20px 15px 20px;
            
                .custom-file {
                    display: none; 
                }
            
                label {
                    width: 160px;
                    margin-bottom: 0 !important;
            
                    .button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 160px;
                        height: 56px;
                    }
                }
            
                .file-input-block {                    
                    width: calc(100% - 185px);
                    height: 100%;
            
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
            
                    border: 1px dashed rgba(223, 231, 233, 0.3);
                    border-radius: 15px;
            
            
                    .file-label {    
                        cursor: pointer;

                        display: flex;
                        align-items: center;

                        width: 100%;
                        height: 100%;

                        padding: 20px 30px;

                        font-size: 12px;
                        font-weight: 400;

                        font-style: italic;
                        color: rgba(255, 255, 255, 0.6);
            
                        margin: 0;    

                        border-radius: 15px;
                        transition: 0.2s ease;
                        
                        &:hover {
                            background: rgba(255,255,255,0.05);
                        }
                    }
                }
            }

            .uploaded-file {
                display: flex;
                justify-content: flex-start;
                align-items: center;
        
                width: 100%;
                height: 50px;
        
                padding: 0 25px;
        
                font-size: 14px;
                line-height: 14px;
        
                color: #fff;
                font-weight: 400;
            }
            .uploaded-file-remove {
                cursor: pointer;
    
                width: 22px;
                height: 22px;
    
                background: url('../../../../images/icons/remove.svg') no-repeat 50% 50%;
                background-size: cover;
    
            }
        }
    }
}

.images-input-block {                    
    width: 100%;
    height: 100%;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    border: 1px dashed rgba(223, 231, 233, 0.3);
    border-radius: 15px;


    .file-label {    
        cursor: pointer;

        display: flex;
        align-items: center;

        width: 100%;
        height: 100%;

        padding: 20px 30px;

        font-size: 12px;
        font-weight: 400;

        font-style: italic;
        color: rgba(255, 255, 255, 0.6);

        margin: 0;    

        border-radius: 15px;
        transition: 0.2s ease;
        
        &:hover {
            background: rgba(255,255,255,0.05);
        }
    }
}

@media screen and (max-width: 767px) {
    .register-form {
        .register-form-row {
            .register-form-block {
                .file-input {
                    flex-wrap: wrap;
                    height: auto;
                    
                    .file-input-block {  
                        flex-wrap: wrap; 
                        width: 100%;
                    }

                    label {
                        width: 100%;

                        .button {
                            margin: 15px auto 10px auto;
                        }
                    }
                }
            }
        }
    }
}