.sidebar-user {
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;

    padding: 25px 0;
    text-align: center;

    .sidebar-user-row {
        display: flex;
        flex-wrap: nowrap;
        
        align-items: center;
        justify-content: center;

        .user-hello {
            .hello-emoji {
                width: 24px;
                height: 24px;

                background: url('../../../images/icons/hey.gif') no-repeat;
                background-size: contain;
                background-position: center;
            }
        }

        .user-name {
            padding-left: 10px;
            padding-right: 20px;
        
            font-size: 16px;
            line-height: 24px;

            font-weight: 700;
            color: #fff;
        }

        .user-avatar {        
            height: 36px;
            width: 36px;

            border-radius: 99px;
            overflow: hidden;

            img{        
                width: 100%;
                height: 100%;

                object-fit: cover;
                object-position: center;    
            }
        }
    }

    &.mob {
        display: none;
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .sidebar-user {
        display: none;
        &.mob{
            display: block;
            bottom: 10px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .sidebar-user {
        display: none;
        &.mob{
            display: block;
            bottom: 10px;
        }
    }
}