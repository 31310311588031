*{
    -ms-overflow-style: none;
}

.MuiTableRow-root td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup {
    background-color: grey !important;
}
.MuiTableCell-root {
    border: none !important;
}
.MuiSelect-icon{
    color:#fff !important;
}
.MuiIconButton-root.Mui-disabled {
    color: grey !important;
}
