
.register-form {
  .register-form-container{
    .register-form-row {
      .error {
        position: relative;
        .label {
          display: flex;
        }
        label {
          // display: inline-block;
          color: red;
        }
        input, select {
          border: red solid 2px !important;
        }
        .error-icon {
          position: absolute;
          top: 47px;
          right: 37px;
          text-align: center;
          width: 24px;
          border-radius: 50%;
          border: #f00 solid 2px;
          color: #f00;
          font-weight: bold;
        }
        .error-message {
          // display: inline-block;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden; 
          white-space: nowrap;
          text-align: right;
          color: red;
        }
        .checkmark {
          border: #f00 solid 2px;
        }
      }      
    }
  }
}
