.register-form-input:disabled {
    background: #a09fa0;
}

.register-form-input {
    width: 100%;
    height: 50px;

    padding: 0 35px;

    background: #fff;
    outline: none;
    border: none;

    font-size: 14px;
    line-height: 20px;

    font-weight: 400;
    color: #000;

    border-radius: 15px;

    &::placeholder {
        color: rgba(0, 0, 0, 0.3);
    }
    &:-internal-autofill-selected {
        -webkit-text-fill-color: #000 !important;
    }

    &:-internal-autofill-selected:hover {
        -webkit-text-fill-color: #000 !important;
    }
}

.register-form-input.register-form-select {
    cursor: pointer;

    appearance: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */

    background: rgba(255, 255, 255, 0.55);
    color: #fff;

    background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5.92857 5L10.8571 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 35px);
    background-position-y: 50%;

    &::before {
        position: absolute;
        content: "";

        top: 0;
        left: 0;

        width: 100px;
        height: 100px;

        background: red;
    }
}
.register-form-input.register-form-select option {
    color: #000 !important;
}
.register-form-input.password {
    // -webkit-text-fill-color: #000 !important;
    letter-spacing: 2px;
    // &:hover {
    //     -webkit-text-fill-color: #000 !important;
    // }
    filter: none;
}
